<template>
  <div class="updateFile">
    <div class="box" @click="onClick">
      <input ref="file"
        type="file"
        class="file-input"
        :accept="accept"
        @change="change"
        capture="camera" />
      <div class="template">
        <slot>
          <img src="../assets/IDbase.png" >
        </slot>
      </div>
      <div class="file-image" v-if="file && accept.indexOf('image') > -1" :style="`background-image: url(${file.base64})`" />
      <div class="file-video" v-if="file && accept.indexOf('video') > -1">
        <video :src="file"></video>
      </div>
      <img class="icon" src="../assets/camera.png">
    </div>
    <mt-progress :value="progress" :bar-height="5" />
  </div>
</template>

<script>
import { mapState } from "vuex";
  import { oneImage } from '../utils/api'
  import lrz from 'lrz'
  export default {
    name: 'UpdateFile',
    props: {
      type: String,
      accept: {
        type: String,
        default: 'image/*'
      },
      url: {
        type: String,
        default: oneImage,
      },
      isSupplement: {
        type: String,
        default: 'N'
      }
    },
    data() {
      return {
        loading: false,
        progress: 0,
        file: null
      };
    },
    computed: {
      ...mapState(["user"]),
    },
    mounted() {
      
    },
    methods: {
      onClick() {
        this.$refs.file.dispatchEvent(new MouseEvent('click')) 
      },
      async change(e) {
        if (this.loading) return
        this.loading = true
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
          if (files[i].type.indexOf('image') > -1) {
            await lrz(files[i], {
              width: 1920,
              height: 1080,
              // quality: 0.8 // 自定义使用压缩方式
            })  
              .then(async (rst) => {
                // 成功时执行
                this.file = rst
                const formData = new FormData()
                formData.append('image', rst.file, rst.origin.name)
                formData.append('applyId', this.user.applyId)
                formData.append('deviceId', 'H5')
                formData.append('type', this.type)
                formData.append('isSupplement', this.isSupplement)
                this.submit(formData).then((e) => {
                  this.$emit('change', e.body, rst);
                }).catch(() => {})
              }).catch((err) => {
                // 失败时执行
                console.log(err)
              })
          } else {
            this.file = URL.createObjectURL(files[i])
            const formData = new FormData()
            formData.append('image', files[i], files[i].name)
            formData.append('applyId', this.user.applyId)
            formData.append('deviceId', 'H5')
            formData.append('type', this.type)
            formData.append('isSupplement', this.isSupplement)
            this.submit(formData).then((e) => {
              this.$emit('change', e.body, files[i]);
            }).catch(() => {})
          }
        }
        this.loading = false
      },
      submit(formData) {
        this.progress = 0
        return this.$axios({
          url: this.url,
          method: 'post',
          data: formData,
          timeout: 120 * 1000,
          onUploadProgress: ({ loaded, total }) => {
            this.progress = (loaded / total) * 100 * 0.9
          }
        }).then((e) => {
          if (e.status.code == "000") {
            this.progress = 100
            return e
          } else {
            this.progress = 0
            this.$toast(e.status.msg);
          }
        }).catch(() => {
          this.progress = 0
        })
      }
    }
  };
</script>

<style lang="scss" scoped>
.updateFile {
  .box {
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(210,210,210,0.5);
    font-size: 0;
    border-radius: 15px;
  }
  .file-input {
    display: none;
  }
  .file-image {
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    img {
      height: 100%;
    }
  }
  .file-video {
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    video {
      height: 100%;
    }
  }
  .template {
    img {
      width: 100%;
    }
  }
  .icon {
    position: absolute;
    width: 25%;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 10px 0px rgba(210,210,210,0.5);
    border-radius: 100%;
  }
}
</style>